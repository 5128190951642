export const initRatingIncrementing = () => {
    // VARS
    const stars = document.querySelectorAll('[data-rating-stars-width]');
    const ratingElems = document.querySelectorAll('[data-rating-incrementing]');
    const ratingCountElems = document.querySelectorAll('[data-rating-count]');
    const startDate = new Date(2023, 5, 19);
    const maxRating = 4.83;
    let initialReviewCount = 4791;
    let initialRating = 4.52;

    // INITS
    const currentRating = increaseRating();
    const currentReviewCount = increaseReviewCount();

    ratingElems.forEach((ratingElem) => {
        ratingElem.textContent = currentRating;
    });

    ratingCountElems.forEach((ratingCountElem) => {
        ratingCountElem.textContent = currentReviewCount;
    });

    stars.forEach((star) => {
        star.style.width = `${(currentRating / 5) * 100}%`;
    });

    // FUNCTIONS
    function increaseRating() {
        const today = new Date();
        const targetDate = new Date(startDate);

        while (targetDate <= today) {
            targetDate.setDate(targetDate.getDate() + 20);
            if (targetDate <= today) {
                initialRating += 0.01;
                initialRating = Math.min(initialRating, maxRating);
            }
        }

        return initialRating.toFixed(2);
    }

    function increaseReviewCount() {
        const today = new Date();
        const targetDate = new Date(startDate);

        while (targetDate <= today) {
            initialReviewCount += 7;
            targetDate.setDate(targetDate.getDate() + 1);
        }

        return initialReviewCount;
    }
};
