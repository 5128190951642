export const initModalImages = () => {
    // VARS
    const buttons = document.querySelectorAll('[data-modal-btn]');

    // LISTENERS
    [...buttons].forEach((button) => {
        button.addEventListener('click', handleOnClickButton, false);
    });

    document.addEventListener('click', handleOnClickDocument, false);

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            closeAllImagesModal();
            enableScroll();
        }
    });

    // HANDLERS
    function handleOnClickButton() {
        openImageModal(this);
        disableScroll();
    }

    function handleOnClickDocument(event) {
        closeImageModal(event);
    }

    // FUNCTIONS
    function openImageModal(button) {
        const modal = button.closest('[data-modal-wrap]').querySelector('[data-modal-image]');
        modal.style.display = 'flex';
    }

    function closeImageModal(event) {
        if (event.target.closest('[data-modal-image]')) {
            event.target.closest('[data-modal-image]').style.display = '';
            enableScroll();
        }
    }

    function closeAllImagesModal() {
        const allModals = document.querySelectorAll('[data-modal-image]');
        allModals.forEach((modal) => {
            modal.style.display = '';
        });
    }
};
