import { setLocationHash } from '../helper-function/setLocation';

export const initTabs = () => {
    // VARS
    const tabButtons = document.querySelectorAll('[data-tab-button]');
    if (!tabButtons.length) return;
    const activeButtonClass = 'tabs__link_active';
    const activeContentClass = 'tabs__pane_show';

    // LISTENERS
    tabButtons.forEach((button) => {
        button.addEventListener('click', handleOnclickButton, false);
    });

    changeTabOnloadPage();

    // HANDLERS
    function handleOnclickButton(event) {
        event.preventDefault();
        changeTab(this);
    }

    // FUNCTIONS
    function changeTab(button) {
        if (button.classList.contains(activeButtonClass)) return;

        const tabContentSelector = button.getAttribute('href');
        const activeTabContent = document.querySelector(`.${activeContentClass}`);
        const activeTabButton = document.querySelector(`.${activeButtonClass}`);
        const currentTabContent = document.querySelector(tabContentSelector);

        setLocationHash(tabContentSelector);

        activeTabContent.classList.remove(activeContentClass);
        activeTabButton.classList.remove(activeButtonClass);

        currentTabContent.classList.add(activeContentClass);
        button.classList.add(activeButtonClass);
    }

    function changeTabOnloadPage() {
        const hash = window.location.hash;
        if (!hash) return;

        const button = document.querySelector(`[data-tab-button="${hash}"]`);
        button && changeTab(button);
    }
};
