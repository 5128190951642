import { initMobileMenu } from './components/initMobileMenu';
import { initAccordion } from './components/initAccordion';
import { initSliders } from './components/initSliders';
import { initTabs } from './components/initTabs';
import { InitRating } from './components/initRating';
import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initJivosite } from './components/initJivosite';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initModals } from './components/initModals';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initRatingIncrementing } from './components/initRatingIncrementing';
import { initScrollLocker } from '../../../../Common/Resources/src_front/js/components/initScrollLocker';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

function handleOnLoadPage() {
    initPageLoader();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initChunkPageBeforeCheckout();
    initScrollLocker();
    initHashEmail();
    const initRating = new InitRating();
    initMobileMenu();
    initAccordion();
    initModals(initRating);
    initSliders();
    initTabs();
    initForms();
    initStickyTableHeader();
    initJivosite();
    initRenderPricesPage();
    initMobileFeatureMenu();
    initScrollToAnchor();
    initLangSwitcher();
    initEmailAutocompleteDropdown();
    initRippleAnimation();
    initRatingIncrementing();
}

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});
