import { getTarget } from '../helper-function/get-target';

export const initLangSwitcher = () => {
    // VARS
    const buttons = document.querySelectorAll('[data-lang-switcher-button]');
    if (!buttons.length) return;

    // LISTENERS
    [...buttons].forEach((button) => {
        button.addEventListener('click', handleOnClickButton, false);
    });

    document.addEventListener(
        'click',
        function (event) {
            if (!getTarget(event, '[data-lang-switcher]')) {
                closeAllLangLists();
            }
        },
        false,
    );

    // HANDLERS
    function handleOnClickButton() {
        toggleLangList(this);
    }

    // FUNCTIONS
    function toggleLangList(button) {
        const switcher = button.closest('[data-lang-switcher]');
        switcher.classList.contains('is-opened') ? closeLangList(switcher) : openLangList(switcher);
    }

    function closeLangList(switcher) {
        switcher.classList.remove('is-opened');
    }

    function openLangList(switcher) {
        switcher.classList.add('is-opened');
    }

    function closeAllLangLists() {
        const switchers = document.querySelectorAll('[data-lang-switcher]');
        [...switchers].forEach((switcher) => {
            switcher.classList.remove('is-opened');
        });
    }
};
